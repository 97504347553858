import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
// import { Col, Paragraph, Row } from 'components';
import { Col } from '../Col/Col';
import { Paragraph } from '../Typography/Typography';
import { Row } from '../Row/Row';
import CounterTitle from '../CounterTitle/CounterTitle';
import Placeholder from '../Placeholder/Placeholder';
import Button from '../Button/Button';
import Table from '../Table/Table';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import { useStoreState } from 'state';
import {
  getInvoiceManualUploadLink,
  getRisksPageLink,
  getSettingsPageLink,
} from 'utils/links';
import { generateTableColumns } from './tableColumnsGenerator';
import { ISummaryItem } from './types';
import { getCashflowSummaries } from 'services/bff';
import { errorHandler } from 'utils/errors';

interface OwnProps {
  shouldFetchForAllEntities?: boolean;
}

const SummaryTable: FC<OwnProps> = ({ shouldFetchForAllEntities = false }) => {
  const history = useHistory();
  const theme = useTheme();
  const { entityId, entityCurrencyCode } = useStoreState(
    ({ UserState }) => UserState
  );
  const [summaries, setSummaries] = useState<ISummaryItem[]>([]);
  const { isIntegrated } = useIntegrationEngine();

  useEffect(() => {
    if (entityId) {
      getCashflowSummaries(shouldFetchForAllEntities)
        .then(({ data }) => {
          console.log('data', data);
          if (data.data) {
            setSummaries(data.data);
          }
        })
        .catch(errorHandler);
    }
  }, [entityId, shouldFetchForAllEntities]);

  const hasCashflows = !!summaries.length;
  const columns = useMemo(
    () =>
      entityCurrencyCode
        ? generateTableColumns({
            detectedCurrencies: summaries.map(({ currency }) => currency),
            entityCurrency: entityCurrencyCode,
            isForAllEntities: shouldFetchForAllEntities,
          })
        : [],
    [entityCurrencyCode, summaries, shouldFetchForAllEntities]
  );

  return (
    <Col>
      {hasCashflows && (
        <>
          <Row mb>
            <CounterTitle title="Summary" />
          </Row>
          <Table<ISummaryItem>
            autoResetSortBy={false}
            sortable
            columns={columns}
            data={summaries}
            onRowClick={({ currency }) => {
              history.push(getRisksPageLink({ currency }));
            }}
            defaultRowHeight={70}
          />
        </>
      )}

      {!hasCashflows && !isIntegrated && (
        <Placeholder description="to see your future currency cashflows">
          <Col gap={theme.spacing.xxs} alignItems="center">
            <Button
              variant="link"
              onClick={() =>
                history.push(getSettingsPageLink({ tab: 'integrations' }))
              }
            >
              + Integrate your accounting system
            </Button>
            <Paragraph>Or</Paragraph>
            <Button
              variant="link"
              onClick={() => history.push(getInvoiceManualUploadLink())}
            >
              Manually upload data
            </Button>
          </Col>
        </Placeholder>
      )}
    </Col>
  );
};

export default SummaryTable;
