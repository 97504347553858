import { useEffect, useState, useMemo } from 'react';
import * as FirebasePaymentInitiation from 'services/firebase/paymentInitiation';
import { callExternalApiWithLoading } from 'utils/fetchers';
import { errorHandler } from 'utils/errors';
import { Notify } from 'utils';
import { IOpenBankingAccount, IOpenBankingIntegration } from 'types';
import { getCurrentBalances, ICurrentBalances } from 'services/balances';
import { useStoreState } from 'state';

export interface IOpenBankingTableAccount {
  id: string;
  isConnected: boolean;
  erpAccount: string;
  balance: number;
  openBankingBalance?: number;
  currency: string;
  accountNumber?: string;
  refuseDisconnect?: boolean;
  institution?: string;
}

const useOpenBankingSettings = () => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(false);
  const [openBankingIntegrations, setOpenBankingIntegrations] = useState<
    IOpenBankingIntegration[]
  >();
  const [currentBalances, setCurrentBalances] = useState<ICurrentBalances>();
  const fetchAccountsIntegrations = async () =>
    callExternalApiWithLoading({
      externalApiCall: FirebasePaymentInitiation.getAccountsIntegrations,
      loadingHandler: setIsLoading,
      responseHandler: (response) => {
        if (response.data) {
          setOpenBankingIntegrations(response.data.data);
        }
      },
    });
  const fetchCurrentBalances = async () => {
    const currentBalancesResponse = await getCurrentBalances();

    if (currentBalancesResponse.data) {
      setCurrentBalances(currentBalancesResponse.data.data);
    }
  };

  useEffect(() => {
    if (entityId) {
      fetchAccountsIntegrations();
      fetchCurrentBalances();
    }
  }, [entityId]);

  const { connectedAccounts, notConnectedAccounts } = useMemo(() => {
    const connectedAccounts: IOpenBankingTableAccount[] = [];
    const notConnectedAccounts: IOpenBankingTableAccount[] = [];

    const openBankingAccountsMap = new Map<
      string,
      { institutionName: string; account: IOpenBankingAccount }
    >();

    openBankingIntegrations?.forEach((integration) => {
      integration.accounts?.forEach((account) => {
        openBankingAccountsMap.set(account.id, {
          institutionName: integration.institutionName,
          account,
        });
      });
    });

    currentBalances?.balances.forEach((balance) => {
      if (balance.source === 'currencyCloud') {
        connectedAccounts.push({
          id: balance.sourceSystemId,
          institution: 'HedgeFlows',
          erpAccount: `HedgeFlows ${balance.currency}`,
          balance: balance.amount,
          currency: balance.currency,
          refuseDisconnect: true,
          isConnected: true,
        });
      }

      const matchingOpenBankingAccount = currentBalances.balances.find(
        (openBankingBalance) =>
          openBankingBalance.source === 'openBanking' &&
          openBankingBalance.accountingSystemId === balance.sourceSystemId
      );

      if (balance.source === 'accountingSystem' && matchingOpenBankingAccount) {
        const matchingIntegration = openBankingIntegrations?.find(
          (integration) =>
            integration.accounts?.some(
              (account) =>
                account.accountId === matchingOpenBankingAccount.sourceSystemId
            )
        );

        connectedAccounts.push({
          id: balance.sourceSystemId,
          institution: matchingIntegration?.institutionName,
          accountNumber: matchingIntegration?.accounts.find(
            (account) =>
              account.accountId === matchingOpenBankingAccount.sourceSystemId
          )?.accountName,
          erpAccount: balance.name,
          balance: balance.amount,
          openBankingBalance: matchingOpenBankingAccount.amount,
          currency: balance.currency,
          isConnected: true,
        });
      }

      if (
        balance.source === 'accountingSystem' &&
        !matchingOpenBankingAccount &&
        !balance.name.includes('HedgeFlows')
      ) {
        notConnectedAccounts.push({
          id: balance.sourceSystemId,
          institution: 'HedgeFlows',
          erpAccount: balance.name,
          balance: balance.amount,
          currency: balance.currency,
          isConnected: false,
        });
      }
    });

    return { connectedAccounts, notConnectedAccounts };
  }, [currentBalances, openBankingIntegrations]);

  const onDisconnectAccountIntegration = async (integrationId: string) => {
    try {
      setIsLoading(true);
      const {
        data,
      } = await FirebasePaymentInitiation.deleteAccountsIntegration(
        integrationId
      );
      if (data.success) {
        Notify.success('Account integration disconnected successfully');
        await fetchAccountsIntegrations();
      } else {
        errorHandler(data);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const hasConnectedAccounts = connectedAccounts.length > 0;
  const hasNotConnectedAccounts = notConnectedAccounts.length > 0;
  const hasAccounts = hasConnectedAccounts || hasNotConnectedAccounts;

  return {
    connectedAccounts,
    notConnectedAccounts,
    isLoading,
    onDisconnectAccountIntegration,
    hasConnectedAccounts,
    hasNotConnectedAccounts,
    hasAccounts,
  };
};

export default useOpenBankingSettings;
