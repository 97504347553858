import { FC, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, RouteComponentProps } from 'react-router-dom';
import Disabled from 'components/shared/Disabled/Disabled';

import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';

import { Firebase } from 'services';

import {
  Overflow,
  StaleCarousel,
  Title,
  Paragraph,
  Loader,
  Col,
} from 'components';
import {
  Wrapper,
  LoginWrap,
  LoginNav,
  LoginContent,
} from '../Login/Login.styles';
import { useStoreActions, useStoreState } from 'state';
import EmailAndPassword from '../Login/components/EmailAndPassword/EmailAndPassword';
import { TInputs } from '../Login/components/EmailAndPassword/types';
import { MultiFactorError } from 'firebase/auth';
import MultiFactorAuth from '../Login/components/MultiFactorAuth/MultiFactorAuth';
import useUrlValues from 'hooks/useUrlValues';
import { useTheme } from 'styled-components';

const LoginNoRedirect: FC<
  RouteComponentProps<
    {},
    any,
    {
      from: {
        pathname: string;
      };
    }
  >
> = () => {
  const theme = useTheme();
  const { sessionId } = useUrlValues('sessionId');

  const [mfaError, setMfaError] = useState<MultiFactorError>();
  const [
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  ] = useState(false);
  const [isTokenSaveSuccess, setIsTokenSaveSuccess] = useState(false);
  const [isLoadingSaveUserAuth, setIsLoadingSaveUserAuth] = useState(false);

  const [error, setError] = useState<string>();

  const { userId, isAuthLoading, userAuth, entityId } = useStoreState(
    (state) => state.UserState
  );
  const { signIn, signInWithGoogle } = useStoreActions(
    (actions) => actions.UserState
  );

  const disableInputs = !sessionId || isAuthLoading;
  const showLoginInputs = !isAuthLoading && !userAuth;
  const showSavingTokenText =
    !isAuthLoading && !!userAuth && !isTokenSaveSuccess && error;

  const onPasswordSignIn = async (values: TInputs) => {
    try {
      const response = (await signIn(values)) as
        | MultiFactorError
        | undefined
        | boolean;

      // user might have 2FA enabled
      if (!!response && response !== true) {
        setMfaError(response);
      }
    } catch (error: any) {
      if (error.code === 'auth/too-many-requests') {
        setIsTooManyPasswordAttemptsError(true);
      }
    }
  };

  const onSignInWithGoogle = async () => {
    const response = await signInWithGoogle();

    // user has 2FA enabled
    if (!!response) {
      setMfaError(response);
    }
  };

  const moveBackToSignIn = useCallback(() => {
    setMfaError(undefined);
  }, []);

  useEffect(() => {
    const handleSignedIn = async () => {
      if (
        !isAuthLoading &&
        !!userAuth &&
        !!userId &&
        !!entityId &&
        !!sessionId
      ) {
        try {
          setIsLoadingSaveUserAuth(true);

          await Firebase.saveUserAuth({
            entityId,
            refreshToken: userAuth.refreshToken,
            sessionId,
          });

          setIsTokenSaveSuccess(true);

          setTimeout(() => {
            console.log('Calling window.close()');
            window.close();
          }, 2000);
        } catch (error: any) {
          setError(`Something went wrong. ${error.message}`);
        } finally {
          setIsLoadingSaveUserAuth(false);
        }
      }
    };

    handleSignedIn();
  }, [isAuthLoading, userId, userAuth, entityId, sessionId, setError]);

  useEffect(() => {
    if (!sessionId) {
      setError('Error Code 400. Missing sessionId parameter.');
    }
  }, [sessionId]);

  return (
    <Wrapper>
      <LoginWrap>
        <LoginNav>
          {isMobile ? (
            <Title variant="h5" color="white">
              Sign in
            </Title>
          ) : (
            <>
              <Title variant="h5">Sign in to HedgeFlows to:</Title>

              <Link to="/sign-up">Sign up</Link>
              <StaleCarousel />
            </>
          )}
        </LoginNav>

        <LoginContent className="rounded">
          <Overflow style={{ paddingBottom: 0 }}>
            <div className="block">
              {showLoginInputs && (
                <>
                  {!isTokenSaveSuccess && (
                    <Disabled isDisabled={disableInputs}>
                      {!!mfaError && (
                        <MultiFactorAuth
                          multiFactorError={mfaError}
                          moveBackToSignIn={moveBackToSignIn}
                        />
                      )}

                      {!mfaError && (
                        <EmailAndPassword
                          onContinue={onPasswordSignIn}
                          onSignInWithGoogle={onSignInWithGoogle}
                          disableSignUp={true}
                        />
                      )}
                    </Disabled>
                  )}
                </>
              )}

              <Col
                justifyContent="center"
                alignItems="center"
                mt
                mtValue={theme.spacing.xl}
                gap={theme.spacing.m}
              >
                {(isLoadingSaveUserAuth || isAuthLoading) && (
                  <Paragraph variant="bold">
                    Checking current authentication status.
                  </Paragraph>
                )}

                {showSavingTokenText && (
                  <Paragraph variant="bold">
                    Confirmed authentication status.
                  </Paragraph>
                )}

                {isTokenSaveSuccess && (
                  <Paragraph variant="bold">
                    Congratulations. We are redirecting your shortly.
                  </Paragraph>
                )}

                {(isLoadingSaveUserAuth ||
                  isAuthLoading ||
                  showSavingTokenText) && <Loader size="medium" />}

                {error && <Paragraph variant="bold">{error}</Paragraph>}
              </Col>
            </div>
          </Overflow>
        </LoginContent>
      </LoginWrap>

      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}
    </Wrapper>
  );
};

export default LoginNoRedirect;
